import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import { IconButton } from '@material-ui/core';

import audiofile from '../../assets/audio/t_bpm5_full.mp3';
import audiofile45 from '../../assets/audio/bpm45.mp3';
import audiofile50 from '../../assets/audio/bpm5.mp3';
import audiofile55 from '../../assets/audio/bpm55.mp3';
import audiofile60 from '../../assets/audio/bpm6.mp3';
import audiofile65 from '../../assets/audio/bpm65.mp3';
import audiofile70 from '../../assets/audio/bpm7.mp3';
import audiofile75 from '../../assets/audio/bpm75.mp3';
import audiofile80 from '../../assets/audio/bpm8.mp3';
import audiofile90 from '../../assets/audio/bpm9.mp3';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import fingerGif from '../../assets/images/PresentationGray.gif'
//import Measure from "react-measure";
import { useUserMedia } from "../../hooks/use-user-media";
import { Canvas, Container, Button, Video, Finger } from "./styles";
import { Base_timer, Base_timer_svg, Base_timer_circle, Base_timer_path_elapsed, Base_timer_path_remaining, Base_timer_label } from "./styles";


import { Popup } from "../popup";

import { Ppgdisplay } from "../ppgdisplay";
import ChoseBreathTone from "../dropdowntones";


const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";


const CAPTURE_OPTIONS = {
    audio: false,
    video: { facingMode: "environment", width: 100, frameRate: { ideal: 30, max: 30 } }
};

const CAPTURE_OPTIONS_USER = {
    audio: false,
    video: { facingMode: "user", width: 100, frameRate: { ideal: 30, max: 30 } }
};

const dropdownStyle = {
    position: 'absolute',
    top: '5px',
    right: '0',
};

const fingerStyle = {
    position: 'absolute',
    top: '50px',
    right: 'calc(50vw - 400px)'
};

const styles = {
    button: {
        position: 'absolute',
        top: '70px',
        right: '50px',
        padding: 0,
    }
};




//const Button = React.forwardRef((props, ref) => {
//    return (
//        <button ref={ref} onClick={props.onClickHandler}>{props.children}</button>
//    )
//})
var myAnimId = "";//your requestId
var stopprocess = true;
//var timeremain = 0; //global variable to track the time remain to cut off the rendering issues for timeup issues.
var conmsg = "";
//export function Camera() {
export function Camera() {

    //const canvasRef = useRef();
    const videoRef = useRef();
    const canvasRef = useRef();

    const [isOpen, setIsOpen] = useState(false);
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);


    let videoConstraints = {
        audio: false,
        video: { facingMode: facingMode, width: 100, frameRate: { ideal: 30, max: 30 } }
    };


    var count = 0;

    const dispatch = useDispatch();

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    // let mediaStream = useUserMedia(CAPTURE_OPTIONS);
    const [mediaStream, setMediaStream] = useState(null);

    const [isCameraEnv, setIsCameraEnv] = useState(true);

    //const mediaStream = useUserMedia(videoConstraints);


    const [isImageReady, setisImageReady] = useState(true);

    const [isCameraOpen, setIsCameraOpen] = useState(true);

    const [isDisplayGIF, setIsDisplayGIF] = useState(true);

    const [debugMsg, setDebugMsg] = useState("message");

    const [toneName, setToneName] = useState(localStorage.getItem("tonename"));

    //dispatch({ type: "CLEAN", point: {}, windowlength: 300 });
    // let audio = new Audio("../../assets/audio/t_bpm5_full.mp3");

    let audio = null;


    // if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    //     videoRef.current.srcObject = mediaStream;

    //     conmsg = "mediastream is set";
    // }

    const TIME_LIMIT = 60;
    // Warning occurs at 10s
    const WARNING_THRESHOLD = TIME_LIMIT / 2;
    // Alert occurs at 5s
    const ALERT_THRESHOLD = TIME_LIMIT / 4;

    const COLOR_CODES = {
        info: {
            color: "green"
        },
        warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
        },
        alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
        }
    };


    const [remainingcolor, setRemainingcolor] = useState("green");
    //const [timeLeft, setTimeLeft] = useState(TIME_LIMIT);
    let timeLeft = TIME_LIMIT;
    //const [strokedasharray, setStrokedasharray] = useState("283");
    const [timePassed, setTimePassed] = useState(0);

    const [timer, setTimer] = useState(0);

    // Credit: Mateusz Rybczonec
    const increment = useRef(null);

    //
    const passedtime = useRef(null);
    const FULL_DASH_ARRAY = 283;


    useEffect(() => {
        async function enableVideoStream() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
                setMediaStream(stream);
                if (videoRef.current && !videoRef.current.srcObject) {
                    videoRef.current.srcObject = stream;
                    conmsg = "mediastream is set";
                }
                console.log("getUserMedia successful");
            } catch (err) {
                console.log("error on getUserMedia: " + err);
                //Handle the error
            }
        }
        console.log("useEffect video stream.");
        if (!mediaStream) {
            console.log("useEffect enable video stream.");
            enableVideoStream();
        } else {
            // return function cleanup() {
            //     console.log("mediaStream cleanup.");
            //     mediaStream.getTracks().forEach(track => {
            //         track.stop();
            //     });
            // };
        }
        setTimeout(() => { setIsDisplayGIF(false) }, 5000); //five seconds

    }, [isCameraEnv]);

    function cleanup() {
        console.log("mediaStream cleanup.");
        if (mediaStream) {
            mediaStream.getTracks().forEach(track => {
                track.stop();
            });
        }
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject = null;
        }
    }

    //try it to close the camera 
    // useEffect(() => {
    //     let videoElement: HTMLVideoElement | undefined

    //     if (videoRef.current) {
    //       videoElement = videoRef.current
    //     }

    //     return () => {
    //       if (videoElement && videoElement.srcObject) {
    //         (videoElement.srcObject as MediaStream).getTracks().forEach((track: any) => {
    //           if (track.readyState == 'live') {
    //             track.stop()
    //           }
    //         })
    //       }
    //     }
    //   }, [videoRef])

    useEffect(() => {
        return () => {
            cleanup();
            console.log("cleanup.");
            // cleanup based on valRef.current
        }
    }, [])

    // let timePassed = 0;

    let timerInterval = null;

    const formatTimetest = () => {
        let remaintime = (TIME_LIMIT - timePassed);

        let minutes = Math.floor(remaintime / 60);

        let seconds = remaintime % 60;

        if (seconds < 10) {
            seconds = `0${seconds}`;
        }
        //setCircleDasharray();
        // setRemainingPathColor(timeLeft);

        if (remaintime <= 0 || remaintime == TIME_LIMIT) {
            if (remaintime <= 0)
                onTimesUp();
            return 'Start';
        }
        else
            // console.log("seconds left: " + seconds);
            return `${minutes}:${seconds}`;

        // const getSeconds = `0${(timer % 60)}`.slice(-2)
        // const minutes = `${Math.floor(timer / 60)}`
        // const getMinutes = `0${minutes % 60}`.slice(-2)
        // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

        // return `${getHours} : ${getMinutes} : ${getSeconds}`
    }
    /*
        const [state, dispatch] = useReducer(reducer, 0);
    
        useEffect(() => {
          setInterval(() => {
            dispatch({type: "Increment"});
          }, 1000);
        }, []);
    */


    //startTimer();

    function onTimesUp() {
        clearInterval(increment.current);
        cancelAnimationFrame(myAnimId);
        stopprocess = true;
        setisImageReady(false);
        setTimePassed((timePassed) => 0);
        setIsOpen(!isOpen);
        stopplaySound();
    }

    function playSound() {
        if (toneName !== null) {
            // audio = new Audio(tonesoundfile);
            //audio = new Audio(tonesoundfile);
            //audio.src = tonesoundfile;
            switch (toneName) {
                case '9':
                    // code block
                    audio = new Audio(audiofile90);
                    break;
                case '8':
                    // code block
                    audio = new Audio(audiofile80);
                    break;
                case '7.5':
                    // code block
                    audio = new Audio(audiofile75);
                    break;
                case '7':
                    // code block
                    audio = new Audio(audiofile70);
                    break;
                case '6.5':

                    // code block
                    audio = new Audio(audiofile65);
                    break;

                case '6':
                    // code block
                    audio = new Audio(audiofile60);
                    break;
                case '5.5':
                    // code block
                    audio = new Audio(audiofile55);
                    break;
                case '5':
                    // code block
                    audio = new Audio(audiofile50);
                    break;
                case '4.5':
                    // code block
                    audio = new Audio(audiofile45);
                    break;
                default:
                    audio = null;
                    break;
                // code block
            }
            if (audio != null) {
                audio.loop = true;
                audio.play();
            }
        }

    }

    function stopplaySound() {

        if (toneName !== null && audio != null) {
            audio.pause();
            audio = null;
        }

    }

    function handleClick() {
        // e.preventDefault();
        console.log('The link was clicked.');
        setDebugMsg(conmsg);
        if (stopprocess) { //if process stoped, then start the proecess.
            dispatch({ type: "CLEAN", point: {}, windowlength: 300 });
            stopprocess = false;
            passedtime.current = 0;
            playSound();
            myAnimId = requestAnimationFrame(draw);
            if (timePassed > 0 && timePassed != 60) {
                setTimePassed((timePassed) => 0);
            } else {
                setTimePassed((timePassed) => 0);
                increment.current = setInterval(() => {
                    setTimePassed((timePassed) => timePassed + 1);
                    passedtime.current = passedtime.current + 1;
                    //console.log("timepassed:" + timePassed);
                    // setTimePassed((timePassed) => timePassed + 1);
                    // setTimeLeft((timeLeft) => TIME_LIMIT - timePassed);
                    //setCircleDasharray();
                    // console.log(timePassed);
                    // console.log(timeLeft);
                    if (TIME_LIMIT - passedtime.current <= 1) {
                        onTimesUp();
                    }
                }, 1000)
            }
        } else { //if stopprocess started, stop the proecss
            clearInterval(increment.current);
            cancelAnimationFrame(myAnimId);
            stopprocess = true;
            setisImageReady(false);
            //stopprocess = true;
            setTimePassed((timePassed) => 0);
            setIsOpen(!isOpen);
            //setisImageReady(false);
        }


    }


    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setTimePassed(timePassed + 1);
    //         setTimeLeft(TIME_LIMIT - timePassed);
    //         setCircleDasharray();
    //         // setRemainingPathColor(timeLeft);
    //         console.log(timePassed);
    //         console.log(timeLeft);
    //         if (timeLeft <= 0) {
    //             onTimesUp();
    //         }
    //     }, 1000);
    //     return () => clearInterval(interval)
    // }, [timePassed]);

    function formatTime(time) {
        const minutes = Math.floor(time / 60);

        let seconds = time % 60;

        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        // console.log("seconds left: " + seconds);
        return `${minutes}:${seconds}`;
    }

    function calculateTimeFraction() {

        const rawTimeFraction = (TIME_LIMIT - timePassed) / TIME_LIMIT;
        //console.log("rawTimeFraction: " + rawTimeFraction);
        //console.log("timePassed: ", timePassed);
        return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    }

    // function setCircleDasharray() {
    //     setStrokedasharray(`${(
    //         calculateTimeFraction() * FULL_DASH_ARRAY
    //     ).toFixed(0)} 283`);
    // }

    const circleDashArray = () => {
        let retString = `${(
            calculateTimeFraction() * FULL_DASH_ARRAY
        ).toFixed(0)} 283`;
        // console.log(retString);
        // return `${(
        //     calculateTimeFraction() * FULL_DASH_ARRAY
        // ).toFixed(0)} 283`;
        return retString;
    }

    const remainingColor = () => {

        const { alert, warning, info } = COLOR_CODES;
        let timeleft = TIME_LIMIT - timePassed;
        let retColor = info.color;
        // If the remaining time is less than or equal to 5, remove the "warning" class and apply the "alert" class.
        if (timeleft <= alert.threshold) {
            retColor = alert.color;

            // If the remaining time is less than or equal to 10, remove the base color and apply the "warning" class.
        } else if (timeleft <= warning.threshold) {
            retColor = warning.color;
        }
        return retColor;
    }

    // let ctx = canvasRef.current.getContext("2d");

    function handleCanPlay() {
        //dispatch({ type: "CLEAN", point: { bright: 0, time: Date.now() }, windowlength: 300 });
        wait(500);
        setIsVideoPlaying(true);

        videoRef.current.play();
        setisImageReady(true);
        stopprocess = true;
        // dispatch({ type: "CLEAN", point: {}, windowlength: 300 });
        // console.log("isImageReady=" + isImageReady);
        //  myAnimId = requestAnimationFrame(draw);
        //console.log("myAnimId" + myAnimId);
    }

    //set torch light for Android phone back camera
    // navigator.mediaDevices.getUserMedia({
    //     video: {
    //       facingMode: 'environment',
    //     }
    //   })
    //   .then((stream) => {
    //     const video = document.querySelector('video');
    //     video.srcObject = stream;

    //     // get the active track of the stream
    //     const track = stream.getVideoTracks()[0];

    //     video.addEventListener('loadedmetadata', (e) => {  
    //       window.setTimeout(() => (
    //         onCapabilitiesReady(track.getCapabilities())
    //       ), 500);
    //     });

    //     function onCapabilitiesReady(capabilities) {
    //       if (capabilities.torch) {
    //         track.applyConstraints({
    //           advanced: [{torch: true}]
    //         })
    //         .catch(e => console.log(e));
    //       }
    //     }

    //   })
    //   .catch(err => console.error('getUserMedia() failed: ', err));

    function draw() {
        // console.log("isImageReady=" + isImageReady);
        if (!stopprocess) {

            var frame = readFrame();
            if (frame) {
                processFrame(frame.data);
                //console.log('get frame');
            }
            myAnimId = requestAnimationFrame(draw);
            //console.log("myAnimId" + myAnimId);

        }
    }

    function readFrame() {
        // if ('ImageCapture' in window) { 
        //     // Has support for API. get image from API plus turn on the flash.
        //   } else {
        //     // No support, use fallback. get image from canvas
        //   }

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        try {
            ctx.drawImage(videoRef.current, 0, 0, 100, 100);

        } catch (e) {
            return null;
        }
        return ctx.getImageData(0, 0, 100, 100);
    }

    const handleClickButton = () => {
        if (!stopprocess) {
            //console.log("isImageReady=" + isImageReady);
            stopprocess = true;
            //console.log("stopprocess=" + stopprocess);
            //console.log("XXXXXXXXXXXmyAnimId" + myAnimId);

            //to-do log out the data.
            cancelAnimationFrame(myAnimId);
            setisImageReady(false);
            //to do      

            // dispatch({ type: "CLEAN", point: {}, windowlength: 300 });
        } else {
            stopprocess = false;
            handleCanPlay();
        }
        setIsOpen(!isOpen);
        //setIsCameraOpen(!isCameraOpen);
        // ref.current.setIsVideoPlaying(isCameraOpen);
    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    function processFrame(data) {
        var len = data.length;
        var sum = 0;
        //console.log('len = ' + len + ',');

        for (var i = 0, j = 0; j < len; i++, j += 4) {
            sum += data[j] + data[j + 1] + data[j + 2];
        }
        //console.log(sum + ',');
        //dispatch to redux.
        //console.log("{ x: " + Date.now() + ", y: " + sum / len + "}");
        dispatch({ type: "ADD", point: { bright: sum / len, time: Date.now() }, windowlength: 300 });
    }

    function wait(ms) {
        var start = new Date().getTime();
        var end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    }

    function getDataFromChoose(message) {
        //setTonesoundfile('../../assets/audio/t_bpm5_full.mp3');
        setToneName(message);
        console.log("Tone name: " + message);
    };

    const switchCameraClick = (e) => {
        // e is of type any so the compiler won't yell at you
        console.log("switch camera.");
        cleanup();
        setMediaStream(null);
        //let camerapos = 1; // 1 is env. 0 is user
        if (isCameraEnv == true) {
            setFacingMode(FACING_MODE_USER);
        }
        else {
            setFacingMode(FACING_MODE_ENVIRONMENT);
        }


        setIsCameraEnv(!isCameraEnv);
    };

    return (
        <Container>
            <div style={dropdownStyle} >
                <ChoseBreathTone sendDataToParent={getDataFromChoose} />
            </div>
            {isDisplayGIF && <div style={fingerStyle}><img src={fingerGif} width='250px' height='150px' /></div>}
            <IconButton style={styles.button} name="switchcamera" size="large" color="primary" onClick={(e) => switchCameraClick(e)}>
                <CameraswitchIcon fontSize="large" sx={{ color: "rgba(230, 255, 110, 0.9)" }} />
            </IconButton>

            <Base_timer>
                <Video ref={videoRef} onCanPlay={handleCanPlay} autoPlay playsInline={true} muted />


                <Canvas ref={canvasRef} />
                <Base_timer_svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <Base_timer_circle>
                        <Base_timer_path_elapsed class="base-timer__path-elapsed" cx="50" cy="50" r="45"></Base_timer_path_elapsed>
                        <Base_timer_path_remaining color={remainingColor()} dashArray={circleDashArray()}
                            id="base-timer-path-remaining"
                            d="
                                M 50, 50
                                m -45, 0
                                a 45,45 0 1,0 90,0
                                a 45,45 0 1,0 -90,0
                            "
                        >
                        </Base_timer_path_remaining>
                    </Base_timer_circle>
                </Base_timer_svg>
                <Base_timer_label id="base-timer-label" onClick={() => handleClick()} >
                    {formatTimetest()}
                </Base_timer_label>
            </Base_timer>


            <Ppgdisplay height="calc(95vh - 356px)" />
            {isOpen && <Popup
                handleClose={togglePopup}
            />}

        </Container>

    );


}


