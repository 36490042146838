import styled, { keyframes, css } from "styled-components";
import { device } from "../../device";

export const Span = styled.span`
content: 'x';
cursor: pointer;
position: fixed;
right: calc(15% - 20px);
top: calc(100vh - 85vh - 33px);
background: #ededed;
width: 25px;
height: 25px;
border-radius: 50%;
line-height: 20px;
text-align: center;
border: 1px solid #999;
font-size: 20px;
`;

export const Message = styled.span`
position: absolute;
width:100%;
height: 50px;
color: red;
background-color:gray;
top: 0;
display: flex;
align-items: center;
justify-content: center;
font-size: 20px;
z-index: 99;
opacity: 0.8;
`;

export const Popupbox = styled.div`
position: fixed;
background: #00000050;
width: 95%;
height: 95vh;
top: 0;
left: 0;
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      height: 100vw;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
`;

export const Box = styled.div`
position: relative;
width: 98%;
margin: 0 auto;
height: 90vh;
max-height: 70vh;
margin-top: calc(100vh - 85vh - 20px);
background: #fff;
border-radius: 4px;
padding: 20px;
border: 1px solid #999;
overflow: auto;
`;
