import React from "react";
import audio1 from '../../assets/audio/t_bpm5_full.mp3';
import { Timer } from "../timer";

export function Setting() {
    const primaryServiceUuid = '49535343-fe7d-4ae5-8fa9-9fafd205e455';//service data from Oximeter BLE
    //49535343-FE7D-4AE5-8FA9-9FAFD205E455
    const receiveCharUuid = '49535343-1e4d-4bd9-ba61-23c647249616'; //character receive
    const sendCharUuid = '12345678-1234-5678-1234-56789abcdef3';


    //for new oximeter export non-filtered signal
    const receiveCharacteristicUUID = '49535343-8841-43F4-A8D4-ECBE34729BB3';
    const sendCharacteristicUUID = '49535343-1E4D-4BD9-BA61-23C647249616'



    var device, sendCharacteristic, receiveCharacteristic;

    var savedBLE = localStorage.getItem("OximeterName");
    var audio = new Audio(audio1);
    var myCharacteristic;
    var peakTime = 0;
    var prePeakTime = -1;


    var hr = 0;
    var avehr = 0;
    var so2 = 0;

    var valuepass = new Array(4);
    var pulsedetected = false;

    var averageBpmIndex = 0;
    var averageBpmArraySize = 3;
    var averageBpmArray = new Array(averageBpmArraySize);

    const handleClick = () => {
        let filters = [];
        // filters.push({ services: [primaryServiceUuid] });
        let options = {};


        filters.push({ namePrefix: "TT" });
        options.filters = filters;
        //options.acceptAllDevices = false;
        console.log('Requesting Bluetooth Device...');
        console.log('with ' + JSON.stringify(options));
        navigator.bluetooth.requestDevice(options)
            .then(device => {
                localStorage.setItem("OximeterName", device.name);
                savedBLE = device.name;
                console.log('> Name:             ' + device.name);
                console.log('> Id:               ' + device.id);
                console.log('> Connected:        ' + device.gatt.connected);
            })
            .catch(error => {
                console.log('Argh! ' + error);
            });
        //setIsCameraOpen(!isCameraOpen);
        // ref.current.setIsVideoPlaying(isCameraOpen);
    };

    const handleConnectClick = () => {
        let filters = [];
        // filters.push({ services: [primaryServiceUuid] });
        let options = {};


        filters.push({ name: savedBLE });
        //filters.push({ services: parseInt(primaryServiceUuid) });
        // filters.push({ optionalServices: [] });



        options.filters = filters;



        console.log('Requesting any Bluetooth Device...');
        navigator.bluetooth.requestDevice({
            filters: [{ name: savedBLE }],
            optionalServices: [primaryServiceUuid]
        })
            .then(device => {
                console.log('Connecting to GATT Server...');
                device.addEventListener('gattserverdisconnected', disconnect);
                return device.gatt.connect();
            })
            .then(server => {
                // Note that we could also get all services that match a specific UUID by
                // passing it to getPrimaryServices().
                console.log('Getting Services...');
                return server.getPrimaryServices();
            })
            .then(services => {
                console.log('Getting Characteristics...');
                let queue = Promise.resolve();
                services.forEach(service => {
                    queue = queue.then(_ => service.getCharacteristics().then(characteristics => {
                        console.log('> Service: ' + service.uuid);
                        characteristics.forEach(characteristic => {
                            if (service.uuid === primaryServiceUuid && characteristic.uuid === receiveCharUuid) {
                                myCharacteristic = characteristic;
                                characteristic.startNotifications();
                                characteristic.addEventListener('characteristicvaluechanged',
                                    onCharacteristicChanged);
                            }
                            console.log('>> Characteristic: ' + characteristic.uuid + ' ' +
                                getSupportedProperties(characteristic));
                        });
                    }));
                });
                return queue;
            })
            .catch(error => {
                console.log('Argh! ' + error);
            });
        //device.ongattserverdisconnected = disconnect;
        audio.loop = true;
        audio.play();

    };

    function onStopButtonClick() {
        if (myCharacteristic) {
            myCharacteristic.stopNotifications()
                .then(_ => {
                    console.log('> Notifications stopped');
                    myCharacteristic.removeEventListener('characteristicvaluechanged',
                        onCharacteristicChanged);
                })
                .catch(error => {
                    console.log('Argh! ' + error);
                });
        }
    }


    function getSupportedProperties(characteristic) {
        let supportedProperties = [];
        for (const p in characteristic.properties) {
            if (characteristic.properties[p] === true) {
                supportedProperties.push(p.toUpperCase());
            }
        }
        return '[' + supportedProperties.join(', ') + ']';
    }

    const onCharacteristicChanged = (event) => {
        //value is byte array
        var data = new Uint8Array(event.target.value.buffer);


        for (const b of data) {
            if ((b & 0x80) > 0 && (b & (1 << 6)) != 0) //first byte deteced and pulse detected
            {
                peakTime = Date.now();
                if (prePeakTime < 0) {
                    prePeakTime = peakTime;
                }
                else {
                    hr = (1000 * 1000 * 60.00) / (peakTime - prePeakTime);
                    console.log("paulse signal:" + peakTime + "heart rate:" + hr);

                    if (averageBpmIndex == averageBpmArraySize) averageBpmIndex = 0;
                    averageBpmArray[averageBpmIndex] = hr;
                    averageBpmIndex++;

                    let beatsBpmArrayAvg = 0;
                    let beatsBpmArrayCnt = 0;
                    for (let i = 0; i < averageBpmArray.length; i++) {
                        if (averageBpmArray[i] > 0) {
                            beatsBpmArrayAvg += averageBpmArray[i];
                            beatsBpmArrayCnt++;
                        }
                    }
                    hr = beatsBpmArrayAvg / beatsBpmArrayCnt;

                    valuepass[1] = hr;
                    valuepass[0] = peakTime;
                    prePeakTime = peakTime;
                    pulsedetected = true;
                }
            }

        }

    }

    /*
            if (Const.UUID_CHARACTER_RECEIVE.equals(characteristic.getUuid())) {
                final byte[] data = characteristic.getValue();
                for(byte b : data)
                {
                    if((b & 0x80) > 0 && (b & (1 << 6)) != 0) //first byte deteced and pulse detected
                    {
                        peakTime = System.currentTimeMillis();
                        if(prePeakTime<0){
                            prePeakTime=peakTime;
                        }
                        else {
                            hr = (long)(1000*1000 * 60.00) / (peakTime - prePeakTime);
                            Log.d("sleepapnea", "paulse signal:" + peakTime + "heart rate:" + hr);
    
                            if (averageBpmIndex == averageBpmArraySize) averageBpmIndex = 0;
                            averageBpmArray[averageBpmIndex] = (float)hr;
                            averageBpmIndex++;
    
                            float beatsBpmArrayAvg = 0;
                            int beatsBpmArrayCnt = 0;
                            for (int i = 0; i < averageBpmArray.length; i++) {
                                if (averageBpmArray[i] > 0) {
                                    beatsBpmArrayAvg += averageBpmArray[i];
                                    beatsBpmArrayCnt++;
                                }
                            }
                            hr = (long)(beatsBpmArrayAvg / beatsBpmArrayCnt);
    
                            valuepass[1]=hr;
                            valuepass[0]=peakTime;
                            prePeakTime = peakTime;
                            pulsedetected = true;
                        }
                       // Log.d("sleepapnea", "paulse signal:"+peakTime+"heart rate:"+hr);
                        bufIndex =0;
                    }
                    if(pulsedetected) {
                        buf[bufIndex] = b;
                        bufIndex++;
                        if (bufIndex == buf.length ) {
                            //so2 is byte[4]
                            valuepass[2] = buf[4];
                            //avehr
                            valuepass[3] = buf[3] | ((buf[2] & 0x40) << 1);
    
                            if (valuepass[1]/1000 < 45 || valuepass[1]/1000 > 150) {
                                valuepass[1] = valuepass[3]*1000;
                            }
    
                            intent.putExtra(SO2_PULSE, valuepass);
                            sendBroadcast(intent);
                            bufIndex = 0;
                            pulsedetected = false;
                        }
                    }
                }
    */


    function toHexString(byteArray) {
        var s = '0x';
        byteArray.forEach(function (byte) {
            s += ('0' + (byte & 0xFF).toString(16)).slice(-2);
        });
        return s;
    }

    const handledisConnectClick = async () => {

        disconnect();
    };

    const disconnect = () => {
        device = null;
        receiveCharacteristic = null;
        audio.pause();
        //sendCharacteristic = null;
    };


    return (
        <div>
            <span> Oximeter device: {savedBLE} </span>
            <button onClick={handleClick}>Scan Oximeter</button>
            <button onClick={handleConnectClick}>Connect</button>
            <button onClick={handledisConnectClick}>Disconnect</button>
        </div>
    );
}