import styled, { keyframes, css } from "styled-components";
import Webcam from "react-webcam";


export const Video = styled.video`
    position: relative;
    width: 640px;
    height: 480px;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
    &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
    }

`;


export const WebCamera = styled(Webcam)`
    position: absolute;
    width: 150px;
    height: 150px;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
    border-radius: 50%;
    object-fit: cover;
    &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
    }

`;

export const Canvas = styled.canvas`
  position: relative;
  width: 640;
  height: 480;
  margin: auto;
  top: 0; left: 0; bottom: 0; right: 0;
`;

