import styled, { keyframes, css } from "styled-components";

export const Container = styled.div`
  font-family: sans-serif;
  display: grid;
  height: 100vh;
  margin: 0;
  
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    background-color: orange;
    transform: translate(-50%, -50%) 
`;

export const Base_timer = styled.div`
position: relative;
width: 300px;
height: 300px;
`;

export const Base_timer_svg = styled.svg`
    transform: scaleX(-1);
  `;

export const Base_timer_circle = styled.g`
    fill: none;
    stroke: none;
  `;

export const Base_timer_path_elapsed = styled.circle`
    stroke-width: 7px;
    stroke: grey;
  `;

export const Base_timer_path_remaining = styled.path`
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
    stroke-dasharray: ${({ dashArray }) => dashArray};
    color: ${({ color }) => handleColorType(color)};
  `;




export const Base_timer_label = styled.span`
    position: absolute;
    width: 300px;
    height: 300px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  `;

const handleColorType = color => {
  switch (color) {
    case "green":
      return "#7db700";
    case "orange":
      return "#FFA500";
    case "red":
      return "#FF0000";
  }
};
