import React from 'react'
import Model from './Model'

const HelpModel = ({ openHelpModal, setOpenHelpModal }) => {

    const [status, setStatus] = React.useState(false);



    return (
        <div>
            <Model open={openHelpModal}>
                <div className="d-flex justify-content-center">
                    <div>
                        <h1 style={{ color: '#5e9ca0' }}>Atrial fibrillation detection using camera</h1>
                        <h2>Disclaim:&nbsp;&nbsp;</h2>
                        <p>No material on this site is intended to be a substitute for professional medical advice, diagnosis or treatment.</p>
                        <h2>How to use:&nbsp;&nbsp;</h2>
                    </div>
                    <div>
                        <ol>
                            <li>Touch and <span >lightly</span> hold your finger on the camera lens and keep your hand still. You can use front or back camera by hit camera switch icon if you use app in your phone.</li>
                            <li>This works best in a <span >well-lit</span> room. If you&rsquo;re in a dimly lit space, point your camera to a light source.</li>
                            <li>The final analyzed result can be zoomed or paned in the view.&nbsp;</li>
                            <li>If you experienced bad signal, make sure your finger is lightly touched the lens. Touch too hrad will block the blood flow in your finger. Also make sure there is enough light lit on your finger.&nbsp;&nbsp;</li>
                        </ol>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            label="Do not display next time"
                            checked={status}
                            onChange={(evt) => {
                                setStatus({
                                    [evt.target.id]: evt.target.checked
                                });
                                //write to local storage
                                localStorage.setItem("showhelp", evt.target.checked);
                            }} />

                        <button onClick={() => setOpenHelpModal(false)}>Acknowledge</button>

                    </div>
                </div>
            </Model>
        </div>
    )
}

export default HelpModel
