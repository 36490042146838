
import React, { useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function Chosedatatypetodisplay({ props, sendDisplaytypeToParent }) {
    const [displaytype, setDisplaytype] = useState(0);  //0 from last measure, 1 from raw data from box no API call for filtering, 2 from processed data

    return (
        <FormControl>
            <FormLabel id="timer">Timer Minutes</FormLabel>
            <RadioGroup
                row
                aria-labelledby="timer"
                name="row-radio-buttons-group"
                value={displaytype}
                onChange={(evt) => {
                    console.log(evt.target.value);
                    setDisplaytype(evt.target.value);
                    sendDisplaytypeToParent(evt.target.value);
                    localStorage.setItem("displaytype", evt.target.value);
                    //console.log(document.activeElement); // <select>

                }}
            >
                <FormControlLabel value="0" control={<Radio />} label="Last Measure" />
                <FormControlLabel value="1" control={<Radio />} label="Raw data" />
                <FormControlLabel value="2" control={<Radio />} label="Processed Data" />
                <FormControlLabel value="3" control={<Radio />} label="PPG and ECG" />
                <FormControlLabel
                    value="disabled"
                    disabled
                    control={<Radio />}
                    label="other"
                />
            </RadioGroup>
        </FormControl>
    );
}
