//import { createStore } from "redux";
import { configureStore } from '@reduxjs/toolkit'
import timerReducer from './slices/timerslice';
import ppgReducer from './slices/ppgSlice';
import pulseReducer from './slices/pulasSlice';

// const initialState = {
//     points: [{ bright: 100, time: Date.now() }],
//     historypoints: [{ bright: 100, time: Date.now() }]
// };

const initialState = {
    points: [],
    historypoints: []
};

//filter the ppg signal

function reducer(state = initialState, action) {
    switch (action.type) {
        case "ADD":

            let newpoints = [...state.points, action.point];
            let newhistorypoints = [...state.historypoints, action.point];
            //            console.log(newpoints.length);
            if (state.points.length > action.windowlength)
                newpoints.shift();

            return {
                ...state,
                points: newpoints,
                historypoints: newhistorypoints
            };
        case "UPDATE":
            let unewpoints = [...action.points];
            let unewhistorypoints = [...state.historypoints];
            //adding one section for every 240 signals, using the last one or close one to append.
            if (action.addto) {
                unewhistorypoints = [...state.historypoints, ...action.points];
                //console.log("**** " + unewhistorypoints.toString());
            }

            //            console.log(newpoints.length);
            return {
                ...state,
                points: unewpoints,
                historypoints: unewhistorypoints
            };
        case "CLEAN":
            //log out all data in the array
            /*
            let mypoints = state.points;
            let index = 0;
            let debugstr = "";
            mypoints.forEach((datapoint) => {
                if (index > 0) debugstr = debugstr + ",";
                debugstr = debugstr + "{ x: " + index + ", y: " + datapoint.bright + " }";
                index = index + 1;
            });
            console.log(debugstr);
            */
            return initialState;

        default:
            return state;
    }
}

//export const store = createStore(reducer);
//export const store = configureStore({ reducer: reducer })

export const store = configureStore({
    reducer: {
        ppg: reducer,
        timer: timerReducer,
        pulse: pulseReducer
    }
});