import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
//import HistoryIcon from '@material-ui/icons/History';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SettingIcon from '@material-ui/icons/Settings';
import CameraSwitchIcon from '@material-ui/icons/CameraAlt';
import MeasureIcon from '@material-ui/icons/Home';

const useStyles = makeStyles({

    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#C0C0C0',
    },
});

export default function SetBottomNavigation() {
    const classes = useStyles();
    const pathname = window.location.pathname; // in case user visits the path directly. The BottomNavBar is able to follow suit.
    console.log('default path ' + pathname);
    const [value, setValue] = React.useState(pathname);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        console.log('user click ' + newValue);
    };

    return (

        <BottomNavigation value={value} onChange={handleChange} showLabels className={classes.stickToBottom}>
            <BottomNavigationAction label="Measure" value="/measure" icon={<MeasureIcon />} component={Link} to='/measure' />
            <BottomNavigationAction label="Meditation" value="/meditation" icon={<SelfImprovementIcon />} component={Link} to='/meditation' />
            <BottomNavigationAction label="Setting" value="/setting" icon={<SettingIcon />} component={Link} to='/setting' />
            <BottomNavigationAction label="CameraSwitch" value="/cameraswitch" icon={<CameraSwitchIcon />} component={Link} to='/cameraswitch' />
            <BottomNavigationAction label="LineGraph" value="linegraph" icon={<ShowChartIcon />} component={Link} to='/linegraph' />
        </BottomNavigation>
    );

}