
import React, { useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function Chosetimer({ props, sendMinutesToParent }) {
    var value = localStorage.getItem("minutes");
    const [minutes, setMinutes] = useState(value === undefined ? 15 : value);

    return (
        <FormControl>
            <FormLabel id="timer">Timer Minutes</FormLabel>
            <RadioGroup
                row
                aria-labelledby="timer"
                name="row-radio-buttons-group"
                value={minutes}
                onChange={(evt) => {
                    setMinutes(evt.target.value);
                    sendMinutesToParent(evt.target.value);
                    localStorage.setItem("minutes", evt.target.value);
                    console.log(document.activeElement); // <select>

                }}
            >
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
                <FormControlLabel value="10" control={<Radio />} label="10" />
                <FormControlLabel value="15" control={<Radio />} label="15" />
                <FormControlLabel value="20" control={<Radio />} label="20" />
                <FormControlLabel value="30" control={<Radio />} label="30" />
                <FormControlLabel
                    value="disabled"
                    disabled
                    control={<Radio />}
                    label="other"
                />
            </RadioGroup>
        </FormControl>
    );
}
