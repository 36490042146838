import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function ChoseBreathTone({ props, sendDataToParent }) {
    const [tonename, setTonename] = React.useState(localStorage.getItem("tonename"));


    return (
        <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="tone">Breath Tone</InputLabel>
            <Select
                labelId="tone"
                id="tone"
                value={tonename}
                label="Tone"
                onChange={(evt) => {
                    setTonename(evt.target.value);
                    sendDataToParent(evt.target.value);
                    localStorage.setItem("tonename", evt.target.value);
                    console.log(document.activeElement); // <select>

                    console.log(document.activeElement); // <body>
                }}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={'9'}>9 Breaths/Minute</MenuItem>
                <MenuItem value={'8'}>8 Breaths/Minute</MenuItem>
                <MenuItem value={'7.5'}>7.5 Breaths/Minute</MenuItem>
                <MenuItem value={'7'}>7 Breaths/Minute</MenuItem>
                <MenuItem value={'6.5'}>6.5 Breaths/Minute</MenuItem>
                <MenuItem value={'6'}>6 Breaths/Minute</MenuItem>
                <MenuItem value={'5.5'}>5.5 Breaths/Minute</MenuItem>
                <MenuItem value={'5'}>5 Breaths/Minute</MenuItem>
                <MenuItem value={'4.5'}>4.5 Breaths/Minute</MenuItem>
            </Select>
        </FormControl>
    );
}
