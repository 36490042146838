
import './App.css';
import { Provider } from 'react-redux';
import React, { useState } from "react";
import Bottomnav from './BottomNav/bottomnav'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { store } from './store';
import { Measure } from './components/measure';
import { Setting } from './components/setting';
import { LineGraph } from './components/linegraph';

import { Timer } from './components/timer';
import { MeditationHRV } from './components/history';
import HelpModel from './components/helplegal/helpmodel';


function App() {

  const [openHelpModal, setOpenHelpModal] = useState(localStorage.getItem("showhelp") == null || localStorage.getItem("showhelp") == false);

  console.log("local storage: = " + localStorage.getItem("showhelp"));
  console.log("openHelpModal: = " + openHelpModal);
  const Routers = () => (
    <div>
      <Switch>
        <Route exact path="/"><Redirect to="/measure" /></Route>
        <Route exact path="/measure" component={Measure} />
        <Route exact path="/meditation" component={MeditationHRV} />
        <Route exact path="/setting" component={Setting} />
        <Route exact path="/cameraswitch" component={HelpText} />
        <Route exact path="/linegraph" component={LineGraph} />
      </Switch>
    </div>
  );



  const Goods = () => {
    return <h1>This is history page</h1>;
  };

  const HelpText = () => {
    return <h1>his is setting page</h1>;
  };


  return (
    <>
      {" "}
      {openHelpModal && (
        <HelpModel
          openHelpModal={openHelpModal}
          setOpenHelpModal={() => setOpenHelpModal(false)}
        />
      )}
      <Provider store={store}>
        <div>
          <HashRouter>
            <div>
              <Routers />
              <Bottomnav />
            </div>
          </HashRouter>
        </div>
      </Provider>
    </>
  );
}

export default App;
