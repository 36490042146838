import styled, { keyframes, css } from "styled-components";
import Webcam from "react-webcam";

export const Container = styled.div`
    position: absolute;
    width: 90%;
    max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
    max-height: ${(maxHeight) => maxHeight && `${maxHeight}px`};
    overflow: hidden;

    font-family: sans-serif;
   
    
    height: calc(100vh - 56px);
    margin: 0;
      
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);

      background-color: gray;
`;
export const Finger = styled.div`
position: 'absolute',
top: '5px',
right: '0'
`;

export const Base_timer = styled.div`
position: relative;
width: 200px;
height: 200px;
margin-left: auto;
margin-right: auto;
margin-top: 1vh;

opacity: 0.8;
`;

export const Video = styled.video`
    position: absolute;
    width: 150px;
    height: 150px;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
    border-radius: 50%;
    object-fit: cover;
    &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
    }

`;


export const WebCamera = styled(Webcam)`
    position: absolute;
    width: 150px;
    height: 150px;
    margin: auto;
    top: 0; left: 0; bottom: 0; right: 0;
    border-radius: 50%;
    object-fit: cover;
    &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
    }

`;

export const Base_timer_label = styled.span`
    position: absolute;
    width: 200px;
    height: 200px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    z-index: 9999;
  `;


export const Canvas = styled.canvas`
  position: relative;
  width: 100;
  height: 100;
  display: none;
  visibility: hidden;

`;

export const Button = styled.button`
  width: 100px;
  height: 50px;
  margin: auto;
  top: 0; left: 0; bottom: 0; right: 0;
  position: absolute;

`;

export const Base_timer_svg = styled.svg`
    transform: scaleX(-1);
  `;


export const Base_timer_path_elapsed = styled.circle`
    stroke-width: 7px;
    stroke: grey;
  `;



export const Base_timer_path_remaining = styled.path`
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
    stroke-dasharray: ${({ dashArray }) => dashArray};
    color: ${({ color }) => handleColorType(color)};
  `;

export const Base_timer_circle = styled.g`
  fill: none;
  stroke: none;
`;



const handleColorType = color => {
  switch (color) {
    case "green":
      return "#7db700";
    case "orange":
      return "#FFA500";
    case "red":
      return "#FF0000";
  }
};