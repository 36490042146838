import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    points: [{ bright: 100, time: Date.now() }],
    historypoints: [{ bright: 100, time: Date.now() }]
};


const ppgSlice = createSlice({
    name: 'ppg',
    initialState,
    reducers: {
        ADD(state, action) {
            let newpoints = [...state.points, action.point];
            let newhistorypoints = [...state.historypoints, action.point];
            //            console.log(newpoints.length);
            if (state.points.length > action.windowlength)
                newpoints.shift();

            return {
                ...state,
                points: newpoints,
                historypoints: newhistorypoints
            };
        },
        CLEAN(state, action) {
            state = initialState;
            return state;
        }
    }
})

export const { ADD, CLEAN } = ppgSlice.actions
export default ppgSlice.reducer