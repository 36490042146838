import styled, { keyframes, css } from "styled-components";

export const Canvas = styled.canvas`
  border: 1px solid #73AD21;
  position: relative;
  overflow: hidden;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  //height: calc(95vh - 356px);
  height: ${props => props.height};
  margin-top: 2vh;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0);
`;