import React, { useState } from "react";
import { Popupbox, Box, Span, Message } from "./style";
import { Heartratedisplay } from "../heartratedisplay";
import { isMobile } from "react-device-detect";
import ErrorBoundary from "../errorboundary";

export const Popup = props => {
    const [isDisplayMessage, setisDisplayMessage] = useState(isMobile);
    var myScreenOrientation = window.screen.orientation;
    // if (isMobile) {
    //     myScreenOrientation.lock("landscape-primary");
    // }
    setTimeout(() => {
        setisDisplayMessage(false)
    }, 3000);

    return (
        <ErrorBoundary>
            <Popupbox >
                <Box >
                    <Span onClick={props.handleClose}>x</Span>
                    < Heartratedisplay rawdata={props.rawdata} />
                    {isDisplayMessage && <Message>Rotate the phone to landscape to view the chart better.</Message>
                    }
                </Box>
            </Popupbox>
        </ErrorBoundary>
    );
};
