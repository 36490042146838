import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";


import { IconButton, Grid, Button } from '@material-ui/core';

import audiofile from '../../assets/audio/t_bpm5_full.mp3';
import audiofile45 from '../../assets/audio/bpm45.mp3';
import audiofile50 from '../../assets/audio/bpm5.mp3';
import audiofile55 from '../../assets/audio/bpm55.mp3';
import audiofile60 from '../../assets/audio/bpm6.mp3';
import audiofile65 from '../../assets/audio/bpm65.mp3';
import audiofile70 from '../../assets/audio/bpm7.mp3';
import audiofile75 from '../../assets/audio/bpm75.mp3';
import audiofile80 from '../../assets/audio/bpm8.mp3';
import audiofile90 from '../../assets/audio/bpm9.mp3';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
//import Measure from "react-measure";
import { useUserMedia } from "../../hooks/use-user-media";
import { Canvas, Video } from "./styles";

//to_do add camera switch to use rear camera 

import { Popup } from "../popup";

import { Ppgdisplay } from "../ppgdisplay";
import ChoseBreathTone from "../dropdowntones";

import Chosetimer from "../chosetimer";
import { RawOff } from "@mui/icons-material";
import { addpulse, cleanuppulse } from "../../slices/pulasSlice";
import { Pulsedisplay } from "../dynamicchart";
import { ConsoleView } from "react-device-detect";


const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";


const CAPTURE_OPTIONS = {
    audio: false,
    video: { facingMode: "environment", width: 100, frameRate: { ideal: 30, max: 30 } }
};

const CAPTURE_OPTIONS_USER = {
    audio: false,
    video: { facingMode: "user", width: 100, frameRate: { ideal: 30, max: 30 } }
};

const dropdownStyle = {
    position: 'absolute',
    top: '5px',
    right: '0',
};

const styles = {
    button: {
        position: 'absolute',
        top: '70px',
        right: '50px',
        padding: 0,
    }
};



var myAnimId = "";//your requestId
var stopprocess = true;
var timerID = null;
//var timeremain = 0; //global variable to track the time remain to cut off the rendering issues for timeup issues.
var conmsg = "";
const targetFps = 30;
//const rppgInterval = 250;
const rppgInterval = 500;

//OpenCV 
const OPENCV_URI = "https://docs.opencv.org/master/opencv.js";
const HAARCASCADE_URI = "haarcascade_frontalface_alt.xml";
//const HAARCASCADE_URI = "haarcascade_frontalface_default.xml";
//haarcascade_frontalface_default.xml

const RESCAN_INTERVAL = 1000;
const DEFAULT_FPS = 30;
const LOW_BPM = 42;
const HIGH_BPM = 240;
const REL_MIN_FACE_SIZE = 0.4;
const SEC_PER_MIN = 60;
const MSEC_PER_SEC = 1000;
const MAX_CORNERS = 10;
const MIN_CORNERS = 5;
const QUALITY_LEVEL = 0.01;
const MIN_DISTANCE = 10;
let opencvLoaded = false;

var frameRGB;
var lastFrameGray;
var frameGray;
var overlayMask;
var cap;
var rawsignal = [];
var timestamps = [];
var fullgreen = [];
var fulltimestamp = [];

var rescan = [];
var starttime = Date.now();
var arsignal = [];
var artimestamps = [];
var corners = [];
var face;
var classifier;
var scanTimer;
var rppgTimer;
var cv;
var faceValid = false;
var lastScanTime = null;
var windowSize = 8;
var videoElement = null;
var numberOfFrame = 0;
var ppgprocesscount = 0;
var ppgsignalcount = 0;

var starttime = 0;
var endtime = 0;

var lastdrawtimestamp = 0;
var lastrppgtimestamp = 0;
var lastdrawvalue = 0;
var frameIntervalID;





export function MeditationHRV() {
    const videoRef = useRef();
    const canvasRef = useRef();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);
    let videoConstraints = {
        audio: false,
        video: { facingMode: facingMode, width: 640, frameRate: { ideal: 30, max: 30 } }
    };
    var count = 0;

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [isCameraEnv, setIsCameraEnv] = useState(true);
    const [isImageReady, setisImageReady] = useState(true);
    const [isCameraOpen, setIsCameraOpen] = useState(true);
    const [debugMsg, setDebugMsg] = useState("message");
    const [toneName, setToneName] = useState(localStorage.getItem("tonename"));
    const [minutes, setMinutes] = useState(localStorage.getItem("minutes"));
    const [opencvLoaded, setOpencvLoaded] = useState(false);
    const initialState = "Start";
    const [startButtonText, setStartButtonText] = useState("Start");
    const [isStarted, setIsStarted] = useState(false);
    const [audio, setAudio] = useState(null);

    const audioRef = useRef(audio);
    audioRef.current = audio;

    const [rawdataDisplay, setRawdataDisplay] = useState(false);

    const pulsehistoryarray = useSelector(state => state.pulse.pulseHistoryArray);

    const pulseArray = useSelector(state => state.pulse.pulseArray);
    const changeText = (text) => setStartButtonText(text);



    //openCV
    // this.webcamId = webcamId;
    // this.canvasId = canvasId,
    // this.classifierPath = classifierPath;
    // this.streaming = false;
    // this.faceValid = false;
    // this.targetFps = targetFps;
    // this.windowSize = windowSize;
    // this.rppgInterval = rppgInterval;



    async function init() {
        // cv = window.cv;
        videoElement = document.getElementById("facevideo");
        frameRGB = new cv.Mat(videoElement.height, videoElement.width, cv.CV_8UC4);
        lastFrameGray = new cv.Mat(videoElement.height, videoElement.width, cv.CV_8UC1);
        frameGray = new cv.Mat(videoElement.height, videoElement.width, cv.CV_8UC1);
        overlayMask = new cv.Mat(videoElement.height, videoElement.width, cv.CV_8UC1);
        cap = new cv.VideoCapture(videoElement);
        starttime = Date.now();
        // Set variables
        rawsignal = []; // 120 x 3 raw rgb values
        timestamps = []; // 120 x 1 timestamps
        arsignal = [];
        artimestamps = [];
        rescan = []; // 120 x 1 rescan bool
        face = new cv.Rect();  // Position of the face
        // Load face detector
        classifier = new cv.CascadeClassifier();

        let faceCascadeFile = "haarcascade_frontalface_alt.xml";

        if (!classifier.load(faceCascadeFile)) {
            await createFileFromUrl(faceCascadeFile, HAARCASCADE_URI);
            try {
                classifier.load(faceCascadeFile)
            } catch (e) {
                console.log("Error load faceCascadeFile:");
                console.log(e);
            }

        }
        numberOfFrame = 0;

        frameIntervalID = setInterval(() => {
            processFrame();
        }, MSEC_PER_SEC / targetFps);

        // setInterval(() => {
        //     rppg();
        // }, rppgInterval);

    }

    function processFrame() {
        try {
            if (!frameGray.empty()) {
                frameGray.copyTo(lastFrameGray); // Save last frame
            }
            cap.read(frameRGB); // Save current frame
            let time = Date.now()
            let rescanFlag = false;
            cv.cvtColor(frameRGB, frameGray, cv.COLOR_RGBA2GRAY);
            // Need to find the face
            if (!faceValid) {
                lastScanTime = time;
                detectFace(frameGray);
            }
            // Scheduled face rescan
            else if (time - lastScanTime >= RESCAN_INTERVAL) {
                lastScanTime = time
                detectFace(frameGray);
                rescanFlag = true;
            }
            // Track face
            else {
                // Disable for now,
                //this.trackFace(this.lastFrameGray, this.frameGray);
            }
            // Update the signal
            if (faceValid) {
                ppgsignalcount = ppgsignalcount + 1;
                // Shift signal buffer
                while (rawsignal.length > targetFps * windowSize) {
                    rawsignal.shift();
                    timestamps.shift();
                    rescan.shift();
                }

                //When signal accumlate to targetFps * windowSize -1 log out the signal and timestamps
                numberOfFrame = numberOfFrame + 1;
                // Get mask
                let mask = new cv.Mat();
                mask = makeMask(frameGray, face);
                // New values
                let means = cv.mean(frameRGB, mask);
                mask.delete();
                // Add new values to raw signal buffer
                //this is the signal value detected from camera
                rawsignal.push(means.slice(0, 3));
                //try to get green signal and process it to see what it looks like

                timestamps.push(time);

                //try to use my own filtering and cure smooth to see the quality of signal

                // fullgreen.push(means.slice(0, 3));
                // fulltimestamp.push(time);

                //  dispatch({ type: "ADD", point: { bright: means.slice(1, 2), time: Date.now() }, windowlength: 300 });

                rescan.push(rescanFlag);
            }
            // Draw face
            cv.rectangle(frameRGB, new cv.Point(face.x, face.y),
                new cv.Point(face.x + face.width, face.y + face.height),
                [0, 255, 0, 255]);

            //draw masked area

            // let pt1 = new cv.Point(Math.round(face.x + 0.3 * face.width),
            // Math.round(face.y + 0.1 * face.height));
            // let pt2 = new cv.Point(Math.round(face.x + 0.7 * face.width),
            // Math.round(face.y + 0.25 * face.height));


            cv.rectangle(frameRGB, new cv.Point(Math.round(face.x + 0.3 * face.width), Math.round(face.y + 0.08 * face.height)),
                new cv.Point(Math.round(face.x + 0.7 * face.width), Math.round(face.y + 0.27 * face.height)),
                [0, 255, 0, 255]);

            // Apply overlayMask
            frameRGB.setTo([255, 0, 0, 255], overlayMask);
            let canvasOutput = document.getElementById("facecanvas");
            cv.imshow("facecanvas", frameRGB);
        } catch (e) {
            console.log("Error capturing frame:");
            console.log(e);
        }
    }

    function detectFace(gray) {
        let faces = new cv.RectVector();
        classifier.detectMultiScale(gray, faces, 1.1, 3, 0);
        if (faces.size() > 0) {
            face = faces.get(0);
            faceValid = true;
        } else {
            console.log("No faces");
            invalidateFace();
        }
        faces.delete();
    }

    function makeMask(frameGray, face) {
        let result = cv.Mat.zeros(frameGray.rows, frameGray.cols, cv.CV_8UC1);
        let white = new cv.Scalar(255, 255, 255, 255);
        let pt1 = new cv.Point(Math.round(face.x + 0.3 * face.width),
            Math.round(face.y + 0.08 * face.height));
        let pt2 = new cv.Point(Math.round(face.x + 0.7 * face.width),
            Math.round(face.y + 0.27 * face.height));
        cv.rectangle(result, pt1, pt2, white, -1);
        return result;
    }

    // Invalidate the face
    function invalidateFace() {
        rawsignal = [];
        timestamps = [];
        rescan = [];
        overlayMask.setTo([0, 0, 0, 0]);
        face = new cv.Rect();
        faceValid = false;
        corners = [];
    }

    function rppg() {
        // Update fps
        let fps = getFps(timestamps);
        //console.log("estimated fps=" + fps + "time=" + Date.now());
        // If valid signal is large enough: estimate
        if (rawsignal.length >= targetFps * windowSize) {
            try {
                ppgprocesscount = ppgprocesscount + 1;
                // Work with cv.Mat from here
                //console.log(Date.now() + " rppg last signal count:" + ppgsignalcount + " start end signal time = " + timestamps[0] + " " + timestamps[rawsignal.length - 1] + " length " + rawsignal.length);
                let rawtimestamps = timestamps.slice();
                let signalmat = cv.matFromArray(rawsignal.length, 1, cv.CV_32FC3,
                    [].concat.apply([], rawsignal));
                let sdata = null;
                // Filtering
                denoise(signalmat, rescan);
                standardize(signalmat);
                detrend(signalmat, fps);
                movingAverage(signalmat, 3, Math.max(Math.floor(fps / 6), 2));
                // HR estimation
                signalmat = selectGreen(signalmat);
                // Draw time domain signal
                overlayMask.setTo([0, 0, 0, 0]);

                //get last 250 ms data from signalmat and time events
                //try to detect heart beat every one second 

                // if (ppgprocesscount % 4 == 0) {
                //     console.log("log start " + ppgprocesscount);

                //     for (var i = 1; i < signalmat.rows; i++) {
                //         console.log("[" + rawtimestamps[i] + "," + signalmat.data32F[i] + "],");
                //     }

                //     console.log("end *********************");
                // }

                // if (ppgprocesscount % 4 == 0) {
                //     console.log("log start " + ppgprocesscount);
                //     for (var i = 1; i < signalmat.rows; i++) {
                //         if (i > 0) {
                //             sdata = sdata + ' ,';
                //         }

                //         if (i == 0) {
                //             sdata = sdata + '{"x": ' + 0 + ', "y":' + (signalmat.data32F[i] * 100).toFixed(3) + '}';
                //         } else
                //             //                            sdata = sdata + '{"x": ' + (rawtimestamps[i] - rawtimestamps[1]) + ', "y":' + (signalmat.data32F[i] * 1000).toFixed(3) + '}';
                //             //sdata = sdata + '{"x": ' + (rawtimestamps[i] - starttime) + ', "y":' + (signalmat.data32F[i] * 100).toFixed(3) + '}';
                //             sdata = sdata + '{"x": ' + rawtimestamps[i] + ', "y":' + (signalmat.data32F[i]).toFixed(3) + '}';
                //     }
                //     console.log(sdata);
                //     console.log("end *********************");
                //     //merge the signalmat rawsignal to accumrawsignal  
                //     //var arsignal=[];
                //     //var artimestamps=[];  
                //     //go backward to look up the index of last timestamp in large array
                //     let lasttimestamp = rawtimestamps[1];
                //     //satrt from end of array atimestamps     
                //     let alen = artimestamps.length;
                //     let k = 0;
                //     let j = 0;
                //     let m = 0;



                //     for (k = alen; k > 0; k--) {
                //         if (artimestamps[k] == lasttimestamp) break;
                //     }
                //     console.log("Timestamp to look: " + lasttimestamp);
                //     console.log("look back number: " + k);

                //     for (j = k; j < alen; j++) {
                //         arsignal[k] = arsignal[k] + signalmat.data32F[m];
                //         m = m + 1;
                //     }

                //     // console.log(signalmat.data32F.slice(m, m + 3));
                //     console.log("data copied m: " + m);

                //     let newArray = signalmat.data32F.slice(m);
                //     artimestamps = artimestamps.concat(rawtimestamps.slice(m));
                //     for (var n = 0; n < newArray.length; n++) {
                //         arsignal.push(newArray[n]);
                //     }
                //     console.log("last y value: " + newArray[newArray.length - 1]);
                //     console.log("last x value: " + artimestamps[artimestamps.length - 1]);
                //     //start to add the y value if timestamp overlaps
                //     //else use add for new timestamp and value    
                //     // if (arsignal.length > 800) {
                //     console.log("total log start ");
                //     sdata = "";
                //     for (var i = 1; i < arsignal.length; i++) {
                //         if (i > 1) {
                //             sdata = sdata + ',';
                //         }

                //         if (i == 0) {
                //             sdata = sdata + '{"x": ' + 0 + ', "y":' + (arsignal[i] * 100).toFixed(3) + '}';
                //         } else
                //             //                            sdata = sdata + '{"x": ' + (rawtimestamps[i] - rawtimestamps[1]) + ', "y":' + (signalmat.data32F[i] * 1000).toFixed(3) + '}';
                //             //sdata = sdata + '{"x": ' + (rawtimestamps[i] - starttime) + ', "y":' + (signalmat.data32F[i] * 100).toFixed(3) + '}';
                //             sdata = sdata + '{"x": ' + artimestamps[i] + ', "y": ' + (arsignal[i]).toFixed(3) + '}';
                //     }
                //     console.log(sdata);
                //     console.log("total end *********************");
                //     // }
                // }



                drawTime(signalmat, rawtimestamps);
                timeToFrequency(signalmat, true);
                // Calculate band spectrum limits
                let low = Math.floor(signalmat.rows * LOW_BPM / SEC_PER_MIN / fps);
                let high = Math.ceil(signalmat.rows * HIGH_BPM / SEC_PER_MIN / fps);
                if (!signalmat.empty()) {
                    // Mask for infeasible frequencies
                    let bandMask = cv.matFromArray(signalmat.rows, 1, cv.CV_8U,
                        new Array(signalmat.rows).fill(0).fill(1, low, high + 1));
                    //drawFrequency(signalmat, low, high, bandMask);
                    // Identify feasible frequency with maximum magnitude
                    let result = cv.minMaxLoc(signalmat, bandMask);
                    bandMask.delete();
                    // Infer BPM
                    let bpm = result.maxLoc.y * fps / signalmat.rows * SEC_PER_MIN;
                    // console.log(bpm);
                    // Draw BPM
                    drawBPM(bpm);
                }
                signalmat.delete();
            } catch (e) {
                console.log("Error process ppg:");
                console.log(e);
            }
            //console.log("finish time=" + Date.now());
        } else {
            console.log("signal too small");
        }
        // }
    }

    function removejscssfile(filename, filetype) {
        var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
        var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for
        var allsuspects = document.getElementsByTagName(targetelement)
        for (var i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
            if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1) {
                console.log("remove javascripts: " + filename);
                allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
            }
        }
    }

    function findjscssfile(filename, filetype) {
        var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
        var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for
        var allsuspects = document.getElementsByTagName(targetelement)
        for (var i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
            if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1) {
                console.log("found javascripts: " + filename);
                return true;
            }
        }
        return false;
    }

    // Load opencv when needed
    async function loadOpenCv(uri) {
        return new Promise(function (resolve, reject) {
            console.log("starting to load opencv");

            var tag = document.createElement('script');
            tag.src = uri;
            tag.async = true;
            tag.type = 'text/javascript'

            tag.onload = () => {
                cv = window.cv;
                cv['onRuntimeInitialized'] = () => {
                    setOpencvLoaded(true);
                    console.log("opencv ready");
                    resolve();
                }
            };

            if (findjscssfile(uri, "js")) {
                setOpencvLoaded(true);
                console.log("opencv ready");
                resolve();

            }

            tag.onerror = () => {
                throw new URIError("opencv didn't load correctly.");
            };
            if (!findjscssfile(uri, "js")) {
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            }
        });
    }

    function getFps(timestamps, timeBase = 1000) {
        if (Array.isArray(timestamps) && timestamps.length) {
            if (timestamps.length == 1) {
                return DEFAULT_FPS;
            } else {
                let diff = timestamps[timestamps.length - 1] - timestamps[0];
                return timestamps.length / diff * timeBase;
            }
        } else {
            return DEFAULT_FPS;
        }
    }
    function denoise(signal, rescan) {
        let diff = new cv.Mat();
        cv.subtract(signal.rowRange(1, signal.rows), signal.rowRange(0, signal.rows - 1), diff);
        for (var i = 1; i < signal.rows; i++) {
            if (rescan[i] == true) {
                let adjV = new cv.MatVector();
                let adjR = cv.matFromArray(signal.rows, 1, cv.CV_32FC1,
                    new Array(signal.rows).fill(0).fill(diff.data32F[(i - 1) * 3], i, signal.rows));
                let adjG = cv.matFromArray(signal.rows, 1, cv.CV_32FC1,
                    new Array(signal.rows).fill(0).fill(diff.data32F[(i - 1) * 3 + 1], i, signal.rows));
                let adjB = cv.matFromArray(signal.rows, 1, cv.CV_32FC1,
                    new Array(signal.rows).fill(0).fill(diff.data32F[(i - 1) * 3 + 2], i, signal.rows));
                adjV.push_back(adjR); adjV.push_back(adjG); adjV.push_back(adjB);
                let adj = new cv.Mat();
                cv.merge(adjV, adj);
                cv.subtract(signal, adj, signal);
                adjV.delete(); adjR.delete(); adjG.delete(); adjB.delete();
                adj.delete();
            }
        }
        diff.delete();
    }

    function standardize(signal) {
        let mean = new cv.Mat();
        let stdDev = new cv.Mat();
        let t1 = new cv.Mat();
        cv.meanStdDev(signal, mean, stdDev, t1);
        let means_c3 = cv.matFromArray(1, 1, cv.CV_32FC3, [mean.data64F[0], mean.data64F[1], mean.data64F[2]]);
        let stdDev_c3 = cv.matFromArray(1, 1, cv.CV_32FC3, [stdDev.data64F[0], stdDev.data64F[1], stdDev.data64F[2]]);
        let means = new cv.Mat(signal.rows, 1, cv.CV_32FC3);
        let stdDevs = new cv.Mat(signal.rows, 1, cv.CV_32FC3);
        cv.repeat(means_c3, signal.rows, 1, means);
        cv.repeat(stdDev_c3, signal.rows, 1, stdDevs);
        cv.subtract(signal, means, signal, t1, -1);
        cv.divide(signal, stdDevs, signal, 1, -1);
        mean.delete(); stdDev.delete(); t1.delete();
        means_c3.delete(); stdDev_c3.delete();
        means.delete(); stdDevs.delete();
    }

    function detrend(signal, lambda) {
        let h = cv.Mat.zeros(signal.rows - 2, signal.rows, cv.CV_32FC1);
        let i = cv.Mat.eye(signal.rows, signal.rows, cv.CV_32FC1);
        let t1 = cv.Mat.ones(signal.rows - 2, 1, cv.CV_32FC1)
        let t2 = cv.matFromArray(signal.rows - 2, 1, cv.CV_32FC1,
            new Array(signal.rows - 2).fill(-2));
        let t3 = new cv.Mat();
        t1.copyTo(h.diag(0)); t2.copyTo(h.diag(1)); t1.copyTo(h.diag(2));
        cv.gemm(h, h, lambda * lambda, t3, 0, h, cv.GEMM_1_T);
        cv.add(i, h, h, t3, -1);
        cv.invert(h, h, cv.DECOMP_LU);
        cv.subtract(i, h, h, t3, -1);
        let s = new cv.MatVector();
        cv.split(signal, s);
        cv.gemm(h, s.get(0), 1, t3, 0, s.get(0), 0);
        cv.gemm(h, s.get(1), 1, t3, 0, s.get(1), 0);
        cv.gemm(h, s.get(2), 1, t3, 0, s.get(2), 0);
        cv.merge(s, signal);
        h.delete(); i.delete();
        t1.delete(); t2.delete(); t3.delete();
        s.delete();
    }

    function movingAverage(signal, n, kernelSize) {
        for (var i = 0; i < n; i++) {
            cv.blur(signal, signal, { height: kernelSize, width: 1 });
        }
    }
    // TODO solve this more elegantly
    function selectGreen(signal) {
        let rgb = new cv.MatVector();
        cv.split(signal, rgb);
        // TODO possible memory leak, delete rgb?
        let result = rgb.get(1);
        rgb.delete();
        return result;
    }

    function timeToFrequency(signal, magnitude) {
        // Prepare planes
        let planes = new cv.MatVector();
        planes.push_back(signal);
        planes.push_back(new cv.Mat.zeros(signal.rows, 1, cv.CV_32F))
        let powerSpectrum = new cv.Mat();
        cv.merge(planes, signal);
        // Fourier transform
        cv.dft(signal, signal, cv.DFT_COMPLEX_OUTPUT);
        if (magnitude) {
            cv.split(signal, planes);
            cv.magnitude(planes.get(0), planes.get(1), signal);
        }
    }
    // Draw time domain signal to overlayMask
    function drawTime(signal, rtimestamps) {
        // Display size
        // let displayHeight = face.height / 2.0;
        // let displayWidth = face.width * 0.8;

        let displayHeight = face.height / 2.0;
        let displayWidth = videoElement.width / 2.0;
        // Signal
        let result = cv.minMaxLoc(signal);
        let heightMult = displayHeight / (result.maxVal - result.minVal);
        let widthMult = displayWidth / (signal.rows - 1);

        //        let drawAreaTlX = face.x + face.width + 10;
        //        let drawAreaTlY = face.y

        let drawAreaTlX = 10;
        let drawAreaTlY = videoElement.height * 0.75;
        let drawarray = [];
        let yvalue = (result.maxVal - signal.data32F[0]) * heightMult;
        let start = new cv.Point(drawAreaTlX,
            drawAreaTlY + yvalue);
        drawarray.push({ bright: yvalue, time: rtimestamps[0] });
        //console.log("start /r/n");
        //console.log("signal.rows = " + signal.rows + "/r/n");
        let lasttimestamp = rtimestamps[rtimestamps.length - 1];
        let starttimestamp = rtimestamps[0];

        let framerate = rtimestamps.length / (lasttimestamp - starttimestamp); //singals per millsecond
        let num = 3 * rppgInterval * framerate; //rppgInterval is half seconds and we needs to have one and half seconds buffer 
        let isToadd = false;
        //if    
        if ((lasttimestamp - lastdrawtimestamp) * framerate > (rtimestamps.length - num)) {
            isToadd = true;
            //find the index to override
            console.log("lastdrawtimestamp = " + lastdrawtimestamp + " endtimestamp= " + lasttimestamp);
            lastdrawtimestamp = lasttimestamp;
        }





        //console.log("num=" + num + "lastdrawtimestamp space = " + (lasttimestamp - lastdrawtimestamp) * framerate);

        //console.log("last time stamp for draw: " + lasttimestamp);
        //if ((starttimestamp - lastdrawtimestamp) > 2000) include = true;
        //look up the timestamp from lastdrawtimestamp in the new rtimestamps
        //push these draw values to Redux for display
        // if (starttimestamp == lastdrawtimestamp) { //push the singnal values to the Redux . //needs to replace previous set of data
        //     console.log("First last " + rtimestamps[0] + "," + (result.maxVal - signal.data32F[i]) * heightMult);
        // }
        //console.log(Date.now() + " last signal count " + ppgsignalcount + " last signal time = " + lasttimestamp + " timrspan = " + (lasttimestamp - rtimestamps[0]));
        for (var i = 1; i < signal.rows; i++) {
            //if (lasttimestamp - rtimestamps[i] <= 250)
            //console.log("signal=" + signal.data32F[i] + " timestamp=" + rtimestamps[i]);
            //console.log(signal.data32F[i] + "," + rtimestamps[i]);

            // if (i > 0) {
            //     sdata = sdata + ' ,';
            // }

            // //try to create random data to draw the pulse time and rate
            // //{"fr":345, "to": 367} time can calculated from time = x[367] - x[345], pulse can be calculated 60.00/(time/6*1000)

            // if (i == 0) {
            //     sdata = sdata + '{"x": ' + 0 + ', "y":' + signal.data32F[i] + '}';
            // } else
            //     sdata = sdata + '{"x": ' + rtimestamps[i] + ', "y":' + signal.data32F[i] + '}';

            //delay two seconds 
            // if (starttimestamp > endtime) {
            //     console.log(signal.data32F[i] + "," + rtimestamps[i]);
            // }
            yvalue = (result.maxVal - signal.data32F[i]) * heightMult;
            let end = new cv.Point(drawAreaTlX + i * widthMult,
                drawAreaTlY + yvalue);
            //console.log(signal.data32F[i] + ",");
            drawarray.push({ bright: yvalue, time: rtimestamps[i] });

            // if (rtimestamps[i] > lastdrawtimestamp) { //push the singnal values to the Redux . //needs to replace previous set of data
            //     lastdrawvalue = (result.maxVal - signal.data32F[i]) * heightMult * diff;
            //     rtime = rtimestamps[i];
            //     dispatch({ type: "ADD", point: { bright: (result.maxVal - signal.data32F[i]) * heightMult, time: rtimestamps[i] }, windowlength: 240 });
            //     //console.log(rtimestamps[i] + "," + (result.maxVal - signal.data32F[i]) * heightMult);
            // }

            // if (rtimestamps[i] == lastdrawtimestamp) { //push the singnal values to the Redux . //needs to replace previous set of data
            //     diff = ((result.maxVal - signal.data32F[i]) * heightMult - lastdrawvalue) / (result.maxVal - signal.data32F[i]) * heightMult;
            //     //console.log("last equal first " + rtimestamps[i] + "," + (result.maxVal - signal.data32F[i]) * heightMult);
            // }

            cv.line(overlayMask, start, end, [255, 255, 255, 255], 2, cv.LINE_4, 0);
            start = end;
        }
        if (starttimestamp > endtime) {
            endtime = lasttimestamp;
        }



        dispatch({ type: "UPDATE", points: drawarray, addto: isToadd });

        if ((lasttimestamp - lastrppgtimestamp) > 1500) { //1500 is one beat for 40 bpm

            //console.log("start detect pulse" + "lasttimestamp=" + lasttimestamp + "lastrppgtimestamp=" + lastrppgtimestamp);
            detectPeak(drawarray.splice(rtimestamps.length - framerate * 8000));
            lastrppgtimestamp = lasttimestamp;

        }
        //lastrppgtimestamp = lasttimestamp;

        // lastdrawtimestamp = lasttimestamp;

        //console.log("end /r/n");
    }

    function drawFrequency(signal, low, high, bandMask) {
        // Display size
        let displayHeight = face.height / 2.0;
        let displayWidth = face.width * 0.8;
        // Signal
        let result = cv.minMaxLoc(signal, bandMask);
        let heightMult = displayHeight / (result.maxVal - result.minVal);
        let widthMult = displayWidth / (high - low);
        let drawAreaTlX = face.x + face.width + 10;
        let drawAreaTlY = face.y + face.height / 2.0;
        let start = new cv.Point(drawAreaTlX,
            drawAreaTlY + (result.maxVal - signal.data32F[low]) * heightMult);
        for (var i = low + 1; i <= high; i++) {
            let end = new cv.Point(drawAreaTlX + (i - low) * widthMult,
                drawAreaTlY + (result.maxVal - signal.data32F[i]) * heightMult);
            cv.line(overlayMask, start, end, [255, 0, 0, 255], 2, cv.LINE_4, 0);
            start = end;
        }
    }


    function drawBPM(bpm) {
        cv.putText(overlayMask, bpm.toFixed(0).toString(),
            new cv.Point(face.x, face.y - 10),
            cv.FONT_HERSHEY_PLAIN, 1.5, [255, 0, 0, 255], 2);
    }

    async function createFileFromUrl(path, url) {
        let request = new XMLHttpRequest();
        request.open('GET', url, true);
        request.responseType = 'arraybuffer';
        request.send();
        return new Promise(resolve => {
            request.onload = () => {
                if (request.readyState === 4) {
                    if (request.status === 200) {
                        let data = new Uint8Array(request.response);
                        cv.FS_createDataFile('/', path, data, true, false, false);
                        resolve(path);
                    } else {
                        console.log('Failed to load ' + url + ' status: ' + request.status);
                    }
                }
            };
        });
    }

    React.useEffect(() => {

        const context = canvasRef.current.getContext('2d');
    });

    React.useEffect(() => {


        const intervalId = setInterval(() => {
            if (isStarted) {
                rppg();
                //console.log("start the interval.");
            }
        }, rppgInterval);

        return () => {
            clearInterval(intervalId);
            //console.log("clean the interval.");
        };
    }, [isStarted]);





    useEffect(() => {
        async function enableVideoStream() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
                setMediaStream(stream);
                if (videoRef.current && !videoRef.current.srcObject) {
                    videoRef.current.srcObject = stream;
                    conmsg = "mediastream is set";
                }
                console.log("getUserMedia successful");
            } catch (err) {
                console.log("error on getUserMedia: " + err);
                //Handle the error
            }
        }
        console.log("useEffect video stream.");

        if (!mediaStream) {
            console.log("useEffect enable video stream.");
            enableVideoStream();
            if (!opencvLoaded)
                loadOpenCv(OPENCV_URI);
        } else {
            return function cleanup() {
                console.log("mediaStream cleanup.");
                mediaStream.getTracks().forEach(track => {
                    track.stop();
                });
            };
        }
        // frameIntervalID = setInterval(() => {
        //     processFrame();
        // }, MSEC_PER_SEC / targetFps);
    }, [isCameraEnv]);

    useEffect(() => {
        if (opencvLoaded) {
            init();

        }

    }, [opencvLoaded]);

    useEffect(() => {
        if (audio != null) {
            if (audio != null) {
                audio.loop = true;
                audio.play();
            }
        }
    }, [audio]);



    function cleanup() {
        console.log("mediaStream cleanup.");
        mediaStream.getTracks().forEach(track => {
            track.stop();
        });
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject = null;
        }

    }

    useEffect(() => {
        return () => {
            clearInterval(frameIntervalID);
            if (mediaStream) cleanup();
            console.log("cleanup.");
            frameRGB.delete();
            lastFrameGray.delete();
            frameGray.delete();
            overlayMask.delete();
            dispatch(cleanuppulse());
            dispatch({ type: "CLEAN", point: {}, windowlength: 300 });
            // cleanup based on valRef.current
        }
    }, [])

    // let timePassed = 0;

    let timerInterval = null;


    /*
        const [state, dispatch] = useReducer(reducer, 0);
     
        useEffect(() => {
          setInterval(() => {
            dispatch({type: "Increment"});
          }, 1000);
        }, []);
    */


    //startTimer();


    function playSound() {
        if (toneName !== null) {
            // audio = new Audio(tonesoundfile);
            //audio = new Audio(tonesoundfile);
            //audio.src = tonesoundfile;
            switch (toneName) {
                case '9':
                    // code block
                    setAudio(new Audio(audiofile90));
                    break;
                case '8':
                    // code block
                    setAudio(new Audio(audiofile80));
                    break;
                case '7.5':
                    // code block
                    setAudio(new Audio(audiofile75));
                    break;
                case '7':
                    // code block
                    setAudio(new Audio(audiofile70));
                    break;
                case '6.5':

                    // code block
                    setAudio(new Audio(audiofile65));
                    break;

                case '6':
                    // code block
                    setAudio(new Audio(audiofile60));
                    break;
                case '5.5':
                    // code block
                    setAudio(new Audio(audiofile55));
                    break;
                case '5':
                    // code block
                    setAudio(new Audio(audiofile50));
                    break;
                case '4.5':
                    // code block
                    setAudio(new Audio(audiofile45));
                    break;
                default:
                    setAudio(null);
                    break;
                // code block
            }

        }

    }

    function stopplaySound() {
        console.log("Stop play the sound.");
        if (toneName !== null && audioRef.current != null) {
            audioRef.current.pause();
            setAudio(null);
        }
        setStartButtonText(initialState);

    }

    function timeup() {
        console.log("Timeup");
        setIsStarted(false);
        stopplaySound();
        setIsOpen(true);
        //print out hr
        // for (let i = 0; i < pulsehistoryarray.length; i++) {
        //     console.log(pulsehistoryarray[i].time);
        // }

    }



    function handleCanPlay() {
        //dispatch({ type: "CLEAN", point: { bright: 0, time: Date.now() }, windowlength: 300 });
        wait(500);
        setIsVideoPlaying(true);

        videoRef.current.play();
        setisImageReady(true);
        stopprocess = true;
        // dispatch({ type: "CLEAN", point: {}, windowlength: 300 });
        // console.log("isImageReady=" + isImageReady);
        //  myAnimId = requestAnimationFrame(draw);
        //console.log("myAnimId" + myAnimId);
    }

    // function processFrame(data) {
    //     var len = data.length;
    //     var sum = 0;
    //     //console.log('len = ' + len + ',');

    //     for (var i = 0, j = 0; j < len; i++, j += 4) {
    //         sum += data[j] + data[j + 1] + data[j + 2];
    //     }
    //     //console.log(sum + ',');
    //     //dispatch to redux.
    //     //console.log("{ x: " + Date.now() + ", y: " + sum / len + "}");
    //     dispatch({ type: "ADD", point: { bright: sum / len, time: Date.now() }, windowlength: 300 });
    // }

    function wait(ms) {
        var start = new Date().getTime();
        var end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    }

    function getDataFromChoose(message) {
        //setTonesoundfile('../../assets/audio/t_bpm5_full.mp3');
        setToneName(message);
        console.log("Tone name: " + message);
    };

    function getMinutesFromChoose(message) {
        //setTonesoundfile('../../assets/audio/t_bpm5_full.mp3');
        setMinutes(message);
        localStorage.setItem("minutes", message);
        console.log("TMinutes: " + message);
    };

    const switchCameraClick = (e) => {
        // e is of type any so the compiler won't yell at you
        console.log("switch camera.");
        clearInterval(frameIntervalID);
        cleanup();
        setMediaStream(null);
        //let camerapos = 1; // 1 is env. 0 is user
        if (isCameraEnv == true) {
            setFacingMode(FACING_MODE_USER);
        }
        else {
            setFacingMode(FACING_MODE_ENVIRONMENT);
        }


        setIsCameraEnv(!isCameraEnv);
        init();

    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    function detectPeak(testdata) {
        //process five beat at time.
        //get average actual frame rates. calculate 100 beats per minutes rate 100/60 = second. 
        //estimate 40 to 100 beats per minutes range, signal usually from 15 to 30 frame per seconds
        //15 frames 40/60 100/60 second   15*40/60 10 frames per beats 30*40/60=20  15*100/60=25 30*100/60=50
        //five beats = 50 250. Use 150 samples per analysis.    
        let len = testdata.length;
        console.log("data len" + len);
        let samplerate = len * 1000 / (testdata[len - 1].time - testdata[0].time);
        console.log("len=" + len + "samplerate=" + samplerate);
        let numbertoprocess = samplerate * 5; //five seconds signal  //process ppg every half second. 
        let peaks = []; //hold peak index 

        let start = 500;
        //start to process at start position of testdata
        let hr = [];
        let up = 0;
        let down = 0;
        let value = -1;
        let index = 0;
        let peaklowindex = 0;
        let peakheighindex = 0;
        let preppglowindex = 0;
        let prepreppglowindex = 0;

        for (let i = 1; i < testdata.length; i++) {
            if (testdata[i].bright > testdata[i - 1].bright) // up now check if it is low point
            {
                up++;
                if (down > 0) // upward but previous is down.
                {
                    if ((testdata[i].bright < testdata[i - 2].bright
                        || (up > 1 && down >= 5))) // either, more than 5 points are down.
                    {
                        // detected low point   //peak already detected.
                        if (peaklowindex == 0) peaklowindex = i - 2;
                        down = 0;
                        // prelowindex = newlowindex;
                        if (testdata[i - 1].bright < testdata[i - 2].bright)
                            peaklowindex = i - 1;
                        else
                            peaklowindex = i - 2;

                        //console.log("lowpeakindex = " + peaklowindex + " x= " + testdata[peaklowindex].time + ", y=" + testdata[peaklowindex].bright);
                        //console.log("peaklowindex=" + peaklowindex + " preppglowindex=" + preppglowindex + " prepreppglowindex=" + prepreppglowindex + " diff=" + (testdata[peaklowindex].time - testdata[preppglowindex].time));
                        if (peaklowindex > 0 && peaklowindex > preppglowindex && preppglowindex > prepreppglowindex && (testdata[peaklowindex].time - testdata[preppglowindex].time) > 600 && (testdata[preppglowindex].time - testdata[prepreppglowindex].time) > 600) {
                            //the peak time should be long enough over 100 bpm = 600 mis. It is valid heart beat 600 could be replaced by average heart rate by half
                            //console.log("preppglowindex = " + preppglowindex + " x= " + testdata[preppglowindex].time + ", y=" + testdata[preppglowindex].bright);
                            console.log("peaklowindex=" + peaklowindex + " preppglowindex=" + preppglowindex + " prepreppglowindex=" + prepreppglowindex);
                            //  console.log("dispatch:" + 60000 / (testdata[preppglowindex].time - testdata[prepreppglowindex].time));
                            dispatch(addpulse({ point: { y: 60000 / (testdata[preppglowindex].time - testdata[prepreppglowindex].time), x: (testdata[preppglowindex].time - starttime) / 1000 }, windowsize: 60 }));

                        }
                        if (peaklowindex > 0) {
                            if (preppglowindex > 0)
                                prepreppglowindex = preppglowindex;
                            preppglowindex = peaklowindex;
                        }
                        peaklowindex = 0;
                    }
                }
            }
            if (testdata[i].bright < testdata[i - 1].bright) {
                down++;
                if (up > 0) // downward but previous is up
                {
                    if (testdata[i].bright < testdata[i - 2].bright && (down > 1 && up >= 5)) {
                        // detected peak point
                        up = 0;

                        if (down == 1) {
                            peakheighindex = i - 1;
                            // down=0;
                        } else if (down == 2) {
                            peakheighindex = i - 2;
                            // down=0;
                        }
                    }
                }
            }



        }

    }

    return (
        <Grid container item direction="column" justifyContent="center" alignItems="center">
            <Grid container spacing={2} direction="row" columns={12}>
                <Grid item xs={3}>
                    <div >
                        <ChoseBreathTone sendDataToParent={getDataFromChoose} />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div >
                        <Chosetimer sendMinutesToParent={getMinutesFromChoose} />
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Button color="success" variant="contained"
                        onClick={() => {
                            if (isStarted) {
                                setIsStarted(false);
                                stopplaySound();
                                setIsOpen(true);
                                // for (let i = 1; i < pulsehistoryarray.length; i++) {

                                //     console.log(60 * 1000 / (pulsehistoryarray[i].time - pulsehistoryarray[i - 1].time));
                                // }
                                //console.log("stop clicked");
                            }
                            else {
                                dispatch({ type: "CLEAN", point: {}, windowlength: 300 });
                                dispatch(cleanuppulse());
                                setIsStarted(true);
                                playSound();
                                timerID = setTimeout(timeup, minutes * 60000);
                                setIsOpen(false);
                            }
                        }}
                    >
                        {isStarted ? 'Stop' : 'Start'}
                    </Button>
                    <IconButton style={styles.button} name="switchcamera" size="large" color="primary" onClick={(e) => switchCameraClick(e)}>
                        <CameraswitchIcon fontSize="large" sx={{ color: "rgba(230, 255, 110, 0.9)" }} />
                    </IconButton>

                </Grid>
            </Grid>
            <Grid item xs={12}>
                <canvas ref={canvasRef} width="640" height="480" id="facecanvas" />
            </Grid>
            <Grid item xs={12}>
                <Video ref={videoRef} id="facevideo" hidden onCanPlay={handleCanPlay} width="320" height="240" autoPlay playsInline={true} muted />
            </Grid>

            <Grid item xs={12}>
                <div >
                    <Pulsedisplay width="600" height="300" />
                </div>
            </Grid>


            <Grid item xs={12}>
                <div >
                    <Ppgdisplay height="calc(70vh - 356px)" />
                </div>
            </Grid>
            {isOpen && <Popup
                handleClose={togglePopup}
                rawdata={rawdataDisplay}
            />}


        </Grid>

    );


}


