import React from "react";
import { Canvas } from "./styles";
import { useStore, useSelector } from 'react-redux';
import { PropaneSharp } from "@mui/icons-material";

export function Ppgdisplay(props) {

    const points = useSelector(state => ({
        points: state.ppg.points
    }));

    const canvasRef = React.useRef(null);
    React.useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let mypoints = points.points;

        if (mypoints.length > 0) {



            var max = mypoints[0].bright;//points[0] && points[0].bright || 0;
            var min = mypoints[0].bright;//points[0] && points[0].bright || 0;


            mypoints.forEach((data) => {
                if (data.bright > max) max = data.bright;
                if (data.bright < min) min = data.bright;
            });

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.beginPath();
            ctx.moveTo(0, 0);
            mypoints.forEach((data, index) => {
                var y = canvas.height * (data.bright - min) / (max - min);
                //console.log('point=' + y);
                ctx.lineTo(index, y);
            });

            //draw vertical scale
            //6 marks. max min (max-min)/5 
            //canvas.height devided by 6 sections


            ctx.lineWidth = 2;

            // set line color
            ctx.strokeStyle = '#ff0000';

            ctx.stroke();

            ctx.font = "8px serif";
            ctx.lineWidth = 1;
            ctx.fillStyle = "blue";

            let x = 0;
            let y = 0;
            let intvel = (canvas.height - 10) / 5;
            for (let step = 0; step <= 5; step++) {
                // Runs 5 times, with values of step 0 through 4.
                ctx.fillText((max - (max - min) * step / 6).toFixed(1), x, intvel * step + 5);
            }



        }

    }, [points])
    return (
        <div>
            <Canvas ref={canvasRef} height={props.height} />
        </div>
    );
}
