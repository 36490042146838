
import { createSlice } from '@reduxjs/toolkit'

export const pulseSlice = createSlice({
    name: 'pulse',
    initialState: {
        pulseArray: [],
        pulseHistoryArray: []
    },
    reducers: {
        addpulse: (state, action) => {
            //if the passin value timestamp less than last point, add it to array and if array size greater than window size, shift
            let len = state.pulseArray.length;
            if (len == 0 || state.pulseArray[len - 1].x < action.payload.point.x) {
                console.log("slice x:" + action.payload.point.x + "y: " + action.payload.point.y);

                state.pulseArray.push(action.payload.point);
                state.pulseHistoryArray.push(action.payload.point);
                //console.log("state.pulseArray=" + state.pulseArray[0].time);
                if (len > action.payload.windowsize) {
                    state.pulseHistoryArray.shift();
                }
            }
        },
        cleanuppulse: state => {
            state.pulseArray = [];
            state.pulseHistoryArray = [];
        }
    }
})

export const { addpulse, cleanuppulse } = pulseSlice.actions

export default pulseSlice.reducer