import React, { useState, useEffect } from "react";
import { PulseCanvas } from "./styles";
import { Chart } from 'chart.js';

import { useSelector } from 'react-redux';

const poptions = {
    type: 'pulse',
    responsive: true,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            type: "linear",
            offset: false,
            gridLines: {
                offsetGridLines: false
            },
            title: {
                display: false,
            }
        }
    },

};



export function Pulsedisplay(props) {

    // const store = useStore();
    //nst [data, setData] = useState(null);
    const canvasRef = React.useRef(null);
    //svar ppdarray;

    const points = useSelector(state => state.pulse.pulseArray);


    // const sendPostRequest = async () => {
    //     try {
    //         console.log("Raw Data", JSON.stringify(ppdarray));

    //         localStorage.setItem("raw_data", JSON.stringify(ppdarray));
    //         const resp = await axios.post(`http://localhost:8080/eventlogs/processppg`, ppdarray);
    //         //const resp = await axios.post(`/eventlogs/processppg`, ppdarray);
    //         console.log("Processed Data", JSON.stringify(resp.data));
    //         localStorage.setItem("processed_data", JSON.stringify(resp.data));

    //         setData(resp.data);
    //     } catch (err) {
    //         // Handle Error Here
    //         //reset the display data as processed data
    //         console.error(err);
    //     }
    // };

    useEffect(() => {
        if (points) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            //to display the data (raw data/ processed data). if r is 1000, it is raw data 
            const myChart = new Chart(ctx, {
                type: "derivedLine",
                data: {
                    //Bring in data
                    datasets: [
                        {
                            data: points,
                            fill: true,
                            customedraw: false,
                            backgroundColor: "rgba(75,192,192,0.2)",
                            borderColor: "rgba(75,192,192,1)"
                        }
                    ]
                },
                options: poptions,
            });

            return () => {
                myChart.destroy()
            }
        }
    }, [points])

    return (
        <div>
            <PulseCanvas
                ref={canvasRef}
            />

        </div>
    );
}
