import React, { useState, useRef, useEffect } from "react";
import { Camera } from "../camera";
import { useDispatch } from "react-redux";




export function Measure() {

    const [isCameraOpen, setIsCameraOpen] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {


        return function cleanup() {
            dispatch({ type: "CLEAN", point: { bright: 0, time: Date.now() }, windowlength: 300 });
            console.log('componentWillUnMount');
        }
    }, [])

    return (
        <div>
            <Camera />

        </div>
    );
}