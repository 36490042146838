import React, { useState, useEffect } from "react";
import { HRCanvas } from "./styles";
import { Chart, LineController } from 'chart.js';
import { registerables } from 'chart.js';
import zoomPlugin from "chartjs-plugin-zoom";
import { useStore } from 'react-redux';
import axios from 'axios';


const options = {
    type: 'heartrateecg',
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        zoom: {
            zoom: {
                wheel: {
                    enabled: true // SET SCROOL ZOOM TO TRUE
                },
                pinch: {
                    enabled: true
                },
                mode: "xy",
                speed: 100
            },
            pan: {
                enabled: true,
                mode: "xy",
                speed: 100
            }
        }
    },
    scales: {
        x: {
            type: "linear",
            offset: false,
            gridLines: {
                offsetGridLines: false
            },
            title: {
                display: false,
            }
        }
    },

};


class CustomLineDouble extends LineController {

    draw() {
        // Call bubble controller method to draw all the points
        super.draw(arguments);

        // Now we can do some custom drawing for this dataset. Here we'll draw a red box around the first point in each dataset
        const meta = this.getMeta();

        //if (!meta._dataset.customedraw) return;
        //const hr = data.datasets[0].hr;
        //console.log(hr);
        //var len = meta.data.length;
        //var i = 0;
        const ctx = this.chart.ctx;

        // //this.ctx.font = "30px Arial";
        // var txt = "Hello";
        // let metrics = ctx.measureText(txt);
        // //   let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        // let textheigh = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        // var ytop = this.chart.scales['y'].top + textheigh * 2;
        // var ybottom = this.chart.scales['y'].bottom + textheigh * 2;
        // var xleft = this.chart.scales['x'].left;
        // ctx.save();
        // ctx.strokeStyle = '#ff0000';
        // ctx.fillStyle = '#ff0000';
        // ctx.beginPath();

        // let pulselistlen = meta._dataset.pulsIndexList.length;
        // var peakList = meta._dataset.pulsIndexList;
        // var start = 0;
        // var end = 0;
        // var linestart = 0;
        // var lineend = 0;

        // var ineend = 0;
        // var sx = 0;
        // var ex = 0;
        // var sy = 0;
        // var ey = 0;
        // var origx = 0;

        // var xScale = this.chart.scales['x'];

        // for (i = 0; i < pulselistlen; i++) {
        //     if (peakList[i] < 0) { //-1 standard for beging or ending of section
        //         start = end = 0;
        //         linestart = lineend = 0;
        //     } else {
        //         if (start === 0) {
        //             linestart = peakList[i];
        //             start = 1;
        //         } else if (end === 0) {
        //             lineend = peakList[i];
        //         }
        //     }
        //     if (linestart > 0 && lineend > 0) { //draw the line and paint the HR

        //         // sx = xScale.getPixelForValue(meta.data[linestart].x);
        //         //  ex = xScale.getPixelForValue(meta.data[lineend].x);
        //         var { x, y } = meta.data[linestart].getProps(['x', 'y']);
        //         sx = x;
        //         sy = y;
        //         var { x, y } = meta.data[lineend].getProps(['x', 'y']);
        //         ex = x;
        //         ey = y;

        //         origx = meta._parsed[lineend].x;
        //         var hr = Math.round(60.0 / ((meta._parsed[lineend].x - meta._parsed[linestart].x) / 1000));

        //         ctx.moveTo(sx, sy);
        //         ctx.lineTo(sx, ytop);

        //         ctx.moveTo(sx, ytop + textheigh);
        //         ctx.lineTo(ex, ytop + textheigh);


        //         ctx.font = "10px Arial";
        //         ctx.fillText(hr.toString(), sx + (ex - sx) / 2 - textheigh / 2, ytop);
        //         linestart = lineend;
        //         start = 1;
        //         end = 0;
        //     }

        // }
        // ctx.stroke();

        // ctx.font = "20px Arial";
        // ctx.fillStyle = "red";
        // //ctx.fillText("Ave. Heart Rate: " + meta._dataset.averageHR, xleft + textheigh, ybottom - 3 * textheigh);
        // //ctx.fillText("Median Heart Rate: " + meta._dataset.medianHR, xleft + textheigh, ybottom - 5 * textheigh);
        // ctx.fillText("Ave. Heart Rate: " + meta._dataset.averageHR, xleft + textheigh, (ybottom + ytop) / 2 - 4 * textheigh);
        // ctx.fillText("Median Heart Rate: " + meta._dataset.medianHR, xleft + textheigh, (ybottom + ytop) / 2 - 6 * textheigh);
        // var message;
        // if (meta._dataset.detection == 0) {
        //     message = "No Atiral Fibrillation Detected.";
        // } else if (meta._dataset.detection == 1) {
        //     message = "Possible Atiral Fibrillation Deteced for your reference.";
        // } else if (meta._dataset.detection == -1) {
        //     message = "Bad signal. Can't detect Atiral Fibrillation.";
        // }
        // //ctx.fillText(message, xleft + textheigh, ybottom - 7 * textheigh);
        // ctx.fillText(message, xleft + textheigh, (ybottom + ytop) / 2 - 8 * textheigh);
        // //       ctx.fillText("Median Heart Rate: " + meta._dataset.medianHR, xleft, ytop + 2 * textheigh);



        // //draw text on top of line


        // ctx.lineWidth = 2;
        // //     //ctx.shadowOffsetX = 0;
        // //     //ctx.shadowOffsetY = 4;
        // ctx.stroke();
        // ctx.restore();
    }
};

CustomLineDouble.id = 'derivedLineDouble';
CustomLineDouble.defaults = LineController.defaults;

// Stores the controller so that the chart initialization routine can look it up
Chart.register(...registerables);

Chart.register(CustomLineDouble);
Chart.register(zoomPlugin);



export function Heartrateecgdisplay(props) {

    const store = useStore();
    const [ecgdata, setEcgdata] = useState(null);
    const [ppgdata, setPpgdata] = useState(null);
    const canvasRef = React.useRef(null);
    var ppdarray;



    const sendPostRequest = async () => {
        try {
            console.log("Raw Data", JSON.stringify(ppdarray));

            localStorage.setItem("raw_data", JSON.stringify(ppdarray));
            const resp = await axios.post(`http://localhost:8080/eventlogs/processppg`, ppdarray);
            //const resp = await axios.post(`/eventlogs/processppg`, ppdarray);
            console.log("Processed Data", JSON.stringify(resp.data));
            localStorage.setItem("processed_data", JSON.stringify(resp.data));

            setPpgdata(resp.data);
        } catch (err) {
            // Handle Error Here
            //reset the display data as processed data
            console.error(err);
        }
    };

    const filter_detect = (mypoints) => {

        //var mypoints = store.getState().ppg.historypoints;
        let index = 0;
        var sdata = "";
        var pulseind = [];
        var yvalue = 0;
        var time = 0;

        var lastpoint = mypoints[0];

        mypoints.forEach((datapoint) => {
            //try to create random data to draw the pulse time and rate
            //{"fr":345, "to": 367} time can calculated from time = x[367] - x[345], pulse can be calculated 60.00/(time/6*1000)
            pulseind.push(index % 5);


            if (index == 0) {
                time = datapoint.time;
                console.log("first sample time = " + time);


                var d = new Date(time);
                console.log("Hour + Minutes + Seconds = " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds());
                d.setHours(d.getHours(), 0, 0, 0)
                time = d.getTime();
                console.log("first sample time in hour = " + time);
                sdata = sdata + '{"x": ' + (datapoint.time - time) + ', "y":' + datapoint.bright + '}';
            } else {
                if (lastpoint.time < datapoint.time) {
                    sdata = sdata + ',{"x": ' + (datapoint.time - time) + ', "y":' + datapoint.bright + '}';
                    //sdata = sdata + ',{"x": ' + (datapoint.time) + ', "y":' + datapoint.bright + '}';
                    lastpoint = datapoint;
                }
            }
            index = index + 1;

        });
        console.log(sdata);
        ppdarray = JSON.parse('[' + sdata + ']');
        if (props.rawdata == null || props.rawdata == false) {
            sendPostRequest();
        }
        else {
            let moddata = "{\"r\":-1,\"ar\":-1,\"mr\":-1,\"ppgdataList\":[" + sdata + "],\"pulseIndexList\":[]}";
            setPpgdata(JSON.parse(moddata));
        }
    }

    React.useEffect(() => {
        setPpgdata(JSON.parse(props.ppgarray));
        setEcgdata(JSON.parse(props.ecgarray));
    }, [])

    useEffect(() => {
        if (ecgdata) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            //to display the data (raw data/ processed data). if r is 1000, it is raw data 
            const myChart = new Chart(ctx, {
                type: "derivedLineDouble",
                data: {
                    //Bring in data
                    datasets: [
                        {
                            label: 'PPG',
                            data: ppgdata.ppgdataList,
                            pulsIndexList: ppgdata.pulseIndexList,
                            averageHR: ppgdata.ar,
                            medianHR: ppgdata.mr,
                            detection: ppgdata.r,
                            customedraw: true,
                            fill: true,
                            backgroundColor: "rgba(75,192,192,0.2)",
                            borderColor: "rgba(75,192,192,1)"
                        },
                        {
                            label: 'ECG',
                            data: ecgdata.ppgdataList,
                            pulsIndexList: ecgdata.pulseIndexList,
                            averageHR: ecgdata.ar,
                            medianHR: ecgdata.mr,
                            detection: ecgdata.r,
                            customedraw: true,
                            fill: true,
                            backgroundColor: "rgba(75,192,192,0.2)",
                            borderColor: 'rgba(200, 0, 0, 1)'
                        }
                    ]
                },
                options: options,
            });

            return () => {
                myChart.destroy()
            }
        }
    }, [ecgdata])

    return (
        <div>
            <HRCanvas
                ref={canvasRef}
            />

        </div>
    );
}
